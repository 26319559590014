.landingTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 27px !important;
    line-height: 30px !important;
}

.landingDescText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.computerView {
    position: relative !important;
}

.box1View {
    position: absolute !important;
    bottom: -18px !important;
    left: 56px !important;
}

.box2View {
    position: absolute !important;
    top: 10px !important;
    right: 0px !important;
}

.landingPartnerTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 27px !important;
    line-height: 32px !important;
    text-align: center !important;
}

.connectPartnerDescText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 16px !important;
}
