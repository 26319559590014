.manageOrderText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 18px !important;
}

.borderView {
    background-color: #FFFFFF !important;
    border: 1px solid #aaa !important;
    -webkit-box-shadow: 0 2px 5px #aaa !important;
    -moz-box-shadow: 0 2px 5px #aaa !important;
    box-shadow: 0 2px 5px #aaa !important;
}
