.mainTitleText {
    font-family: InterBold !important;
    font-size: 18px !important;
    text-decoration-line: underline !important;
}

.contentText {
    font-family: InterMedium !important;
    font-size: 15px !important;
}

.titleText {
    font-family: InterBold !important;
    font-size: 15px !important;
}
