.mainView {
}

.titleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 27px !important;
    line-height: 30px !important;
}

.descText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.desc2Text {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 19px !important;
}

.mainBoxView {
    position: relative !important;
    background-color: #FFFFFF !important;
    border: 1px solid #128807 !important;
    height: 200px !important;
    overflow: hidden !important;
}

.stepView {
    background-color: #D8F0D4 !important;
    height: 60px !important;
    width: 35% !important;
    position: absolute !important;
    top: 0 !important;
    left: -59px !important;
    transform: rotate(-50deg);
}

.stepText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 13px !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
}

.stepContentText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.landingImageView {
    height: 90% !important;
    width: 90% !important;
}

.landingImageStyle {
    height: 100% !important;
    width: 100% !important;
}
