.mainView {
    border: 1px solid #aaa !important;
}

.borderBottomLine {
    /*border-bottom: 1px solid #aaa;*/
}

.labelMainView {
}

.labelView {
    cursor: pointer !important;
}

.labelText {
    color: #000 !important;
    font-size: 13px !important;
}
