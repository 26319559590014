.mainTitleText {
    font-family: InterBold !important;
    font-size: 18px !important;
    text-decoration-line: underline !important;
}

.contentText {
    font-family: InterMedium !important;
    font-size: 15px !important;
}

.linkText {
    color: #315CCB !important;
    font-family: InterMedium !important;
    font-size: 15px !important;
    text-decoration-line: none !important;
}
