.manageInventoryText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 18px !important;
}

.borderView {
    background-color: #FFFFFF !important;
    border: 1px solid #aaa !important;
    -webkit-box-shadow: 0 2px 5px #aaa !important;
    -moz-box-shadow: 0 2px 5px #aaa !important;
    box-shadow: 0 2px 5px #aaa !important;
}

.skuBoxView {
    border: 1px solid #e1e3ea !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    height: 40px !important
}

.skuText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.productCountView {
    background-color: #000 !important;
    border-radius: 3px !important;
    height: 40px !important;
}

.productCountText {
    color: #fff !important;
    font-size: 15px !important;
    font-family: InterBold !important;
}

.productTitleText {
    color: #fff !important;
    font-size: 14px !important;
    font-family: InterBold !important;
}

.msgBorderView {
   border: 1px solid #55AC10 !important
}

.congratulationMsgText {
    color: #55AC10 !important;
    font-size: 14px !important;
    font-family: InterMedium !important;
}

.skuMsgText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.descMsgText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.msgPriceBorderView {
    border: 1px solid #999 !important
}

.priceUpdatedMsgText {
    color: #55AC10 !important;
    font-size: 16px !important;
    font-family: InterBold !important;
}
