.dataView {
    width: 20% !important;
}

.labelText {
    color: #315CCB !important;
    font-family: 'InterBold' !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
}

.subLabelText {
    color: #315CCB !important;
    font-family: InterMedium !important;
    text-transform: capitalize !important;
    font-size: 11px !important;
}

.descView {
    background-color: #fff !important;
    border: 1px solid #aaa !important;
    -webkit-box-shadow: 0 2px 5px #aaa !important;
    -moz-box-shadow: 0 2px 5px #aaa !important;
    box-shadow: 0 2px 5px #aaa !important;
}

.descTitleText {
    color: red !important;
    font-family: InterBold !important;
    font-size: 14px !important;
}

.descText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.descItalicText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterItalic !important;
}

.shippedText {
    color: #315CCB !important;
    font-size: 14px !important;
    font-family: InterBoldItalic !important;
}

.shippedDescText {
    color: #000 !important;
    font-size: 14px !important;
    font-family: InterItalic !important;
}

.iconBoxView {
    background-color: #315CCB !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 30px !important;
}

.tableHeadRow {
    border: 1px solid #aaa !important;
}

.tableRow {
    background-color: #FFFFFF !important;
    border: 1px solid #aaa !important;
}

.closeIconView {
    cursor: pointer !important;
}
