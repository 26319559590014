.headerView {
    border: 1px solid #aaa !important;
    -webkit-box-shadow: 0 2px 5px #aaa !important;
    -moz-box-shadow: 0 2px 5px #aaa !important;
    box-shadow: 0 2px 5px #aaa !important;
    height: 70px !important;
}

.trebbsText {
    font-family: InterBold !important;
    font-size: 22px !important;
}

.tradeText {
    color: #000;
    font-family: InterBold !important;
    font-size: 11px !important;
    margin-top: -6px !important;
}

.sellerLoginView {
    cursor: pointer !important;
}

.sellerLoginText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 13px !important;
}
