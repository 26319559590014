.skuText {
    color: #000;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.productNameTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.valueText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.valueSubText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 12px !important;
}

.statusText {
    font-size: 13px !important;
    font-family: InterBold !important;
    text-transform: capitalize !important;
}

.btnView {
    cursor: pointer !important;
}


