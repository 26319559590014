.imgView {
    height: 30px;
    width: 30px
}

.imgStyle {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.dataView {
    width: 400px !important;
}

.dataView1 {
    width: 120px !important;
}

.dataView2 {
    width: 150px !important;
}

.skuText {
    color: #000;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.productNameTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.productNameText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.hsCodeText {
    color: #000;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.pricePerKgPackageText {
    color: red;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.sellerText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.tgsinText {
    color: #000;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.orderUidText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.OrderTimestampText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.orderQuantityText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.quantityText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.totalPriceText {
    color: #000 !important;
    font-size: 15px !important;
    font-family: InterMedium !important;
}

.shippingCostText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.cargoPriceText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.userCargoPriceText {
    color: #000 !important;
    font-size: 15px !important;
    font-family: InterMedium !important;
}

.pricePerKgText {
    color: #000 !important;
    font-size: 15px !important;
    font-family: InterMedium !important;
}

.pricePerPackageText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.shippingModeText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.incotermText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.deliveryDateText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.shippingDateText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.orderStatusText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.paymentStatusText {
    color: #000 !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
    font-family: InterMedium !important;
}

.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.btnView {
    cursor: pointer !important;
}
