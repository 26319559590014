.titleText {
    color: #315CCB !important;
    font-size: 13px !important;
    font-family: InterBold !important;
}

.valueText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.mainText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.tableRow {
    border: 1px solid #aaa !important;
}

.errorDocument {
    color: red !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.addMoreText {
    cursor: pointer !important;
    color: #315CCB !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}
